.sbs_container {
    display: flex;
    gap: 60px;
    align-items: center;
    justify-content: space-between;
    min-height: 600px;
}

.sbs_img {
    object-fit: contain;
    max-height: 500px;
    max-width: 100%;
    margin: 0 auto;
}

.sbs_aside {
    text-align: left;
    flex-shrink: 0;
    flex-basis: 40%;
    display: block;

    p {
        margin-top: 0;
    }

    h2 {
        font-size: var(--font-size-h2);
        line-height: var(--font-size-h2);
        font-weight: 700;
        margin-block-start: 0;
        margin-block-end: 10px;
        max-width: 370px;
    }
}

.rtl {
    flex-direction: row-reverse;
}

.ltr {
    flex-direction: row;
}

.cta_button {
    width: fit-content;
}

@media (max-width: 1130px) {
    .sbs_img {
        max-height: 350px;
    }

    .sbs_container {
        min-height: 500px;
    }
}

@media (max-width: 960px) {
    .sbs_container {
        gap: 30px;
        min-height: 400px;
    }

    .sbs_img {
        max-height: 350px;
    }
}

@media (max-width: 860px) {
    .sbs_container {
        min-height: 350px;
    }

    .sbs_img {
        max-height: 275px;
    }
}

@media (max-width: 725px) {
    .sbs_container {
        flex-direction: column;
        gap: 0px;
        margin: 0;
        justify-content: center;
        padding-block-end: 60px;
        padding-block-start: 30px;
    }

    .sbs_aside {
        max-width: 500px;
        margin-block-start: 20px;
    }

    .cta_button {
        margin: 0 auto;
    }
}
