.container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: var(--margin-bigger);

    .column {
        display: flex;
        .slotsHeader {
            display: none;
        }
    }

    .column.hasSelectedDay {
        display: block;
        .slotsHeader {
            display: block;
            font-size: var(--font-size-bigger);
            font-weight: var(--font-weight-bold);
            margin: 1rem 0;
            text-align: center;
        }
    }

    .slotsPlaceholder {
        display: flex;
        flex: auto;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .placeholderArrow {
            padding: var(--padding-smaller);
        }
    }

    .isLoading {
        filter: blur(2px);
    }
}

@media screen and (max-width: 900px) {
    .container {
        flex-direction: column;
        align-items: center;

        .slotsPlaceholder {
            flex-direction: column;

            .placeholderArrow {
                transform: rotate(90deg);
            }
        }
    }
}

.spinnerOut {
    width: 3rem;
    margin: 10rem auto;
    display: block;
}

.spinnerIn {
    display: block;
    stroke: var(--brand-color);
}

.animate__animated {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;

    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animate__animated.animate__infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.animate__animated.animate__infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

@media (prefers-reduced-motion: reduce), print {
    .animate__animated {
        -webkit-animation-duration: 1ms !important;
        animation-duration: 1ms !important;
        -webkit-transition-duration: 1ms !important;
        transition-duration: 1ms !important;
        -webkit-animation-iteration-count: 1 !important;
        animation-iteration-count: 1 !important;
    }

    .animate__animated[class*='Out'] {
        opacity: 0;
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

.animate__pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}
