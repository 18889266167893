.mobileView {
    display: initial;
}

.desktopView {
    display: none;
}

@media screen and (min-width: 650px) {
    .mobileView {
        display: none;
    }
    .desktopView {
        display: initial;
    }
}
