.collections {
    width: 100%;
}

.collectionsSnapScroller {
    ul {
        column-gap: var(--padding-small);
    }
}

.collectionsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--margin-bigger);
}

@media only screen and (max-width: 900px) {
    .collectionsGrid {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 600px) {
    .collectionsGrid {
        grid-template-columns: 1fr;
    }
}
