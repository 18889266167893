.footer {
    margin-block: var(--margin-big);
    display: flex;
    justify-content: center;
    a {
        color: var(--text-alt);
    }
    position: sticky;
    top: 100vh;
}
