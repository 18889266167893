.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block-start: var(--margin-big);

    h3 {
        margin-block-start: 0;
    }

    .socialList {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        gap: var(--margin);
        svg {
            width: 1.25em;
            height: 1.25em;
            fill: var(--link-color);

            &:hover {
                fill: var(--blue-hover);
            }
        }
    }
}
