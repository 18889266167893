.avatarContainer {
    --avatarHeight: 200px;
    --avatarWidth: 200px;

    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    .avatar {
        width: var(--avatarWidth);
        height: var(--avatarHeight);
        border-radius: 50%;
    }

    .placeholder {
        width: var(--avatarWidth);
        height: var(--avatarHeight);
    }

    .uploadOverlay {
        opacity: 0;
        position: absolute;
        width: var(--avatarWidth);
        height: var(--avatarHeight);
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: var(--white);

        .uploadIcon {
            height: 60px;
            width: 60px;
            margin-block-end: var(--margin);

            path {
                fill: var(--white);
            }
        }
    }

    .progressOverlay {
        position: absolute;
        width: var(--avatarWidth);
        height: var(--avatarHeight);
        display: none;
        justify-content: center;
        align-items: center;

        &[data-visible='true'] {
            display: flex;
        }

        meter {
            width: 90%;
        }
    }

    .fileInput {
        opacity: 0;
        position: absolute;
        width: var(--avatarWidth);
        height: var(--avatarHeight);
        cursor: pointer;
    }

    &:hover .uploadOverlay {
        opacity: 1;
    }
}

.successAlert {
    margin-block-end: var(--margin);
}

.removeButton {
    margin-inline-end: var(--margin);
}

.errorAlert {
    margin-block-end: unset;
}
