.MediaUploading {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1004;
    pointer-events: none;
    background-color: rgba(132, 130, 130, 0.5);
}
