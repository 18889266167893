.occurrencesSection {
    width: 100%;
}

.occurrenceList {
    list-style: none;
    margin: 0;
    padding: 0;
}

.locationFilters {
    display: flex;
    justify-content: end;
}

.occurrenceListBtn {
    font-size: 0.75rem;
    margin: var(--margin-big) auto;
}
