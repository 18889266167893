.container {
    margin: 40px 0;
}

.option {
    border: 2px solid transparent;
    padding: 14px 16px;
    border-radius: var(--rounded-corners-standard);
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 14px;
    justify-content: start;
    align-items: center;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 0px 1px var(--border);
    margin-block-end: var(--margin);
}

.option:hover {
    cursor: pointer;
    box-shadow: 0 0 0px 1px var(--blue);
}

.option.checked {
    background-color: var(--blue25);
    border: 2px solid var(--blue);
    box-shadow: none;
}

.option input[type='radio'] {
    margin: 0px;
    width: 20px;
    height: 20px;
}

.duration {
    font-weight: var(--font-weight-bolder);
    margin-inline-end: 5px;
}

.cost {
    font-size: var(--font-size);
    font-weight: var(--font-weight-bolder);
}

.description {
    color: var(--text-muted);
    font-size: var(--font-size-smaller);
}
