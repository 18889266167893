.slots {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-inline: var(--padding-small);
    justify-content: center;

    li {
        outline: none;
    }
}
