.badge {
    border-radius: var(--rounded-corners-pill);
    background-color: var(--bg-grey);
    padding-block: var(--padding-smaller);
    padding-inline: var(--padding-small);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--black);
    white-space: nowrap;
}

.container {
    > ul {
        display: flex;
        gap: var(--margin-small);
        flex-wrap: wrap;
    }
}
