.confirmationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    text-align: center;

    .header {
        margin-block-start: 0.5em;
        margin-block-end: 0em;
    }

    .tickIcon {
        fill: var(--green);
        width: 100px;
    }

    .referenceNumber {
        color: var(--text-muted);
        margin-block-end: 1em;
    }

    .coursesButton {
        max-width: fit-content;
    }
}
