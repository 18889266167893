.pageContainer {
    display: grid;
    grid-template-columns: 4fr 3fr;
    column-gap: 10vmin;
    grid-template-areas: 'primary summary';
}

.primarySection {
    grid-area: primary;

    h1 {
        margin: 0;
        margin-block-end: 1em;
    }
}

.summarySection {
    grid-area: summary;
}

@media screen and (max-width: 767px) {
    .pageContainer {
        grid-template-columns: auto;
        grid-template-areas:
            'summary'
            'primary';
    }

    .primarySection {
        h1 {
            margin-block-start: var(--margin);
        }
    }
}
