.filterSection {
    border-block-end: 1px solid var(--border-muted);
    padding-block-end: var(--padding);
    > label {
        display: block;
        font-size: var(--font-size-h2);
        margin-block: var(--padding);
    }
    fieldset {
        border: none;
        padding: 0;
    }
    &:first-of-type {
        > label {
            margin-block-start: 0;
        }
    }
    &:last-of-type {
        padding-block-end: 0;
        border-block-end: none;
    }
}

.collectionFilter {
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--padding);
}

.selectedCollection {
    background: var(--bg-3);
    border: 1px solid black;
}
