.banner_container {
    height: 20em;
}

.banner_image {
    position: relative;
    height: 100%;
    max-width: 100%;

    img {
        margin: 0;
        border-radius: var(--rounded-corners-pill);
    }
}

.background_gradient {
    z-index: 1;
    position: absolute;
    border-radius: var(--rounded-corners-pill);
    height: 100%;
    width: 100%;
    background: linear-gradient(
        to right,
        rgb(0, 0, 1),
        rgb(0, 0, 0.8),
        rgba(0, 0, 0, 0.199),
        rgba(0, 0, 0, 0.1)
    );
    display: grid;

    .cta_section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-inline-start: 5em;
        color: #fff;
        align-content: center;

        h2 {
            margin: 0;
            font-size: 2em;
        }

        p {
            font-size: 1em;
            max-width: 50%;
        }

        a {
            color: var(--text);
        }
    }
}

@media screen and (max-width: 640px) {
    .banner_image {
        img {
            border-radius: var(--rounded-corners-pronounced);
        }
    }

    .banner_container {
        height: 14em;
    }

    .background_gradient {
        border-radius: var(--rounded-corners-pronounced);

        .cta_section {
            margin-inline-start: 2em;

            h2 {
                margin: 0;
                font-size: 1.2em;
            }

            p {
                max-width: 70%;
            }

            a {
                color: var(--text);
                font-size: 1em;
            }
        }
    }
}
