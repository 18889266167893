.container {
    border: 1px solid var(--border);
    padding: var(--padding-small);
    border-radius: var(--rounded-corners-standard);
    border: 2px solid transparent;
    box-shadow: 0 0 0px 1px var(--border);
    transition: all 0.2s ease-in-out;
    margin-block-end: 8px;
}
.container:hover {
    cursor: pointer;
    box-shadow: 0 0 0px 1px var(--brand-color);
}

.container.checked {
    box-shadow: none;
    border: 2px solid var(--brand-color);
    background: var(--brand-hover-color);
}

.optionData {
    display: grid;
    grid-template-columns: 26px auto 1fr;
    gap: var(--padding-small);
    align-items: center;
}

.optionData > input[type='radio'] {
    margin-inline-start: 5px;
    width: 20px;
    height: 20px;
    accent-color: var(--brand-color);
}

.optionData svg {
    height: 1em;
    width: 1em;
    background: var(--border-muted);
    padding: var(--padding-small);
    border-radius: 10px;
    fill: var(--brand-color);
    border: 1px solid transparent;
}

.container:hover .optionData svg {
    border: 1px solid var(--brand-color);
}

.checked .optionData svg {
    border: 1px solid var(--brand-color);
}

.optionConfig hr {
    margin: var(--padding-small) 0;
    border: 0.5px solid var(--border);
}

.label {
    font-weight: var(--font-weight-bolder);
}
