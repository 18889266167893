.header {
    border: 1px solid var(--border);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    padding: var(--padding);
    gap: var(--padding-small);
    align-items: center;

    .headerMonth {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--padding);
    }

    .headerLeft {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: var(--padding);
    }

    .headerRight {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: var(--padding);
        button[aria-expanded='true'] {
            background: var(--bg-grey-dark);
        }
    }
}
