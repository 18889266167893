.container {
    display: flex;
    flex-direction: column;
    gap: var(--gap-list);
}

.payBtn {
    width: 100%;

    svg {
        width: var(--font-size);
        height: var(--font-size);
        margin-inline-end: var(--margin);
    }
}
