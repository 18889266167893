.poll {
    display: flex;
    margin-block-start: var(--margin-big);

    .pollInset {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--margin);

        margin-inline-start: var(--margin);
        border-left: 2px solid var(--border);
        padding: var(--padding);
        width: 100%;
    }

    .addOptionButton {
        width: 100%;
        border: 1px dashed var(--border);
        border-radius: var(--border-radius);
        background: unset;
        cursor: pointer;
        padding: var(--padding);
        display: flex;
        align-items: center;
        justify-content: left;
        color: var(--text-muted);

        transition: display 0.3s ease-in;

        &:disabled {
            display: none;
        }
    }

    .errorContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
        color: var(--red);
        gap: var(--margin-small);

        svg {
            path {
                fill: var(--red);
            }
        }

        p {
            margin: unset;
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        p {
            margin: unset;
            color: var(--text-muted);
            font-size: var(--font-size-extra-small);
        }

        .removePollButton {
            height: unset;
            padding-inline: var(--padding-tiny);
        }
    }

    @media screen and (max-width: 767px) {
        padding: unset;

        .pollInset {
            margin-inline-start: unset;
            border-left: unset;
            padding: unset;
        }
    }
}
