.Toolbar {
    list-style: none;
    display: flex;
    gap: var(--gap-list-smaller);
    margin: 0;
    padding: 0;
}

.Button {
    border: none;
    fill: var(--text-muted);
    padding: 0 var(--padding-small);
    &:hover,
    &:focus,
    &:active {
        fill: var(--text-primary);
        border: none;
    }

    &:disabled,
    &:disabled:hover {
        opacity: 0.5;
    }
}

.HiddenInput {
    display: none;
}
