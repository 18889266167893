.container {
    display: none;
    margin-block-start: var(--margin-small);
}

.backButton {
    font-size: var(--font-size-extra-small);

    svg {
        width: 0.75em;
        height: 0.75em;
    }
}

.coursePageBreadcrumbsList {
    ol > li {
        font-size: var(--font-size-extra-small);

        &::before {
            color: var(--text);
        }
    }

    a {
        color: var(--text);

        &:hover {
            color: var(--link-color);
        }
    }
}

@media screen and (min-width: 768px) {
    .container {
        display: flex;
        align-items: center;
        gap: var(--gap-list-smaller);
        height: 2.5em;
    }
}
