.container {
    margin-bottom: 40px;
}

.addressField {
    margin-block-start: var(--margin);
}

.addressField input {
    box-sizing: border-box;
}

.select_location_label {
    display: block;
    margin-block-end: var(--margin-small);
    font-weight: var(--font-weight-bolder);
}
