.event {
    display: block;
    color: var(--text);
    font-size: var(--font-size-smaller);

    time {
        color: var(--text-muted);
        display: block;
    }

    border-inline-start: 3px solid var(--brand-color);
    margin-inline: var(--margin-small);
    padding-inline-start: var(--margin-small);
    padding-block: 2px;
    margin-block-end: var(--margin-small);
    &:hover {
        text-decoration: underline;
    }

    &.colour1 {
        border-color: #3366ff;
    }

    &.colour2 {
        border-color: #52bd94;
    }

    &.colour3 {
        border-color: #ffb020;
    }

    &.colour4 {
        border-color: #d14343;
    }

    &.colour5 {
        border-color: #897ae3;
    }

    &.colour6 {
        border-color: #25cbd6;
    }

    &.colour7 {
        border-color: #ed55c2;
    }

    &.colour8 {
        border-color: #de7548;
    }

    &.colour9 {
        border-color: #9db5ff;
    }
}
