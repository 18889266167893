.container select {
    width: 100%;
    padding: var(--padding-small);
    border-radius: var(--border-radius);
    cursor: pointer;
    appearance: none;

    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
}

.container .option {
    background: none;
    color: inherit;
    cursor: pointer;
}

.container .option.focussed {
    outline: -webkit-focus-ring-color auto 1px;
}
