.dateChoice {
    display: flex;
    align-items: center;
    gap: var(--gap-list);
    padding-block-end: var(--padding-small);

    > button {
        border: 1px solid transparent;
        color: var(--red);

        &:disabled {
            cursor: not-allowed;
            color: var(--text-muted);
        }
    }
}

.addDate {
    > button {
        gap: var(--gap-list-small);

        svg {
            width: var(--font-size);
            height: var(--font-size);
        }
    }
}
