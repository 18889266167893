.AttachmentList {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--gap-list-small);
    padding: 0;
    width: 100%;
    margin: var(--margin) 0;

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
}
