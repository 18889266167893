.container {
    display: none;
}

.backButton,
.disabledBackButton {
    gap: var(--gap-list-smaller);
    padding-inline: 0;
    font-size: var(--font-size);

    svg {
        width: 0.75em;
        height: 0.75em;
    }
}

.backButton:hover {
    color: var(--brand-color);

    svg {
        fill: var(--brand-color);
    }
}

.angleRight {
    color: var(--brand-color);
}

@media screen and (min-width: 768px) {
    .container {
        display: flex;
        align-items: center;
        gap: var(--gap-list-smaller);
        height: 2.5em;
        margin-block: var(--margin);
    }
}
