.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: var(--padding);
    margin-block-end: var(--margin-big);

    nav {
        display: flex;
        align-items: center;
        gap: var(--padding);
    }
}

.educatorLogoAndTitle {
    display: flex;
    align-items: center;
    color: var(--text-heading);
    gap: var(--margin);
    h2 {
        margin: 0;
    }

    .logoContainer {
        img {
            object-fit: contain;
            margin: 0;
        }
    }
}

@media screen and (min-width: 600px) {
}

@media screen and (max-width: 767px) {
    header {
        .adminDashboardButton {
            display: none;
        }
    }
}
