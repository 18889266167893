.heading {
    margin: 0;
}

.reference {
    color: var(--text-muted);
    font-size: var(--font-size-small);
    margin-block: var(--margin-big);
    display: block;
}

.buttons {
    display: flex;
    gap: var(--gap-list);
    align-items: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
    .buttons {
        flex-direction: column;

        a {
            width: 100%;
        }
    }
}
