.innerContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 100px 0;
    img {
        width: auto;
        max-height: 350px;
    }
}

@media screen and (max-width: 650px) {
    .innerContainer {
        grid-template-columns: 1fr;
        margin: 30px 0;

        .image_container {
            grid-row-start: 1;
            text-align: center;

            img {
                max-width: 50%;
                object-fit: contain;
            }
        }
    }
}
