.payButton {
    margin-block-start: var(--margin-bigger);
    width: 100%;

    svg {
        width: var(--font-size);
        height: var(--font-size);
        margin-inline-end: var(--margin);
    }
}

.errorAlert {
    margin-block: var(--margin);
    min-width: 100%;
}

.spinnerOut {
    width: 3rem;
    margin: 10rem auto;
    display: block;
}

.spinnerIn {
    display: block;
    stroke: var(--brand-color);
}
