.confirmationStepHeader {
    display: flex;
    align-items: center;
    gap: var(--gap-list-small);
    margin-block-end: 1em;
}

.checkoutStepHeader {
    margin: 0;
}

.bookingConfirmedIcon {
    height: 1.75em;
    width: 1.75em;
    fill: var(--green);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

@media screen and (max-width: 767px) {
    .confirmationStepHeader {
        flex-direction: column;
        justify-content: center;
    }

    .bookingConfirmedIcon {
        height: 3.5em;
        width: 3.5em;
        margin-block: var(--margin);
    }
}
