.vouchersPage {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
    }
}

.primarySection {
    width: 100%;
    max-width: 600px;
}
