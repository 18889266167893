.logos_banner {
    display: block;
    width: 100%;
    position: relative;
    padding-block-end: 3rem;

    h4 {
        text-align: center;
        margin-block-end: 2rem;
    }
}

.logos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    justify-items: center;

    :last-child {
        display: none;
    }
}

@media screen and (min-width: 767px) {
    .logos {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (min-width: 1000px) {
    .logos {
        grid-template-columns: repeat(5, 1fr);

        :last-child {
            display: block;
        }
    }
}
