.videoThumbnail {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: var(--border-radius);
    overflow: hidden;

    .playButton {
        max-width: 5vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: var(--white);
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
    }
}
