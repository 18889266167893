.container {
    padding-block-start: 5rem;
    overflow: hidden;
    width: 100%;
}

.carousel {
    display: flex;
    gap: 2rem;
    margin: 0 2rem;
    animation: sideScroll 120s infinite linear;
}

@keyframes sideScroll {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-190rem);
    }

    100% {
        transform: translateX(0);
    }
}
