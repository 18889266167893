.loadMoreContainer {
    display: flex;
    justify-content: center;
    margin-top: 1em;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.loadingIcon {
    height: var(--font-size);
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    margin-inline-end: var(--padding-tiny);
}
