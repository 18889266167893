.subscriptionBadges {
    margin-block-end: var(--margin);

    .badge {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;

        background-color: #d7e0fe;
        color: #3d6cfa;

        border-radius: var(--rounded-corners-pronounced);
        margin-block-end: var(--margin-small);
        padding: 2px;
        padding-inline: var(--padding);

        font-size: var(--font-size-smaller);
        font-weight: var(--font-weight-bold);

        max-width: max-content;

        svg {
            margin-inline-end: var(--margin-small);

            path {
                fill: #3d6cfa;
            }
        }
    }
}
