.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.card {
    max-width: 600px;
    min-width: min(90%, 600px);
    margin-inline: 0.5em;
    margin-block-end: 1em;

    h3 {
        margin-block-start: 0em;
        margin-block-end: 1em;
    }

    &:last-of-type {
        margin-block-end: 0em;
    }
}

.breadcrumbs {
    margin-block-end: 1em;
}

.cardsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
