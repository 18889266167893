.educator_hidden_bio,
.educator_bio {
    position: relative;

    p {
        margin: 0;
    }
}

.educator_hidden_bio {
    max-height: calc(var(--font-size-h2) * 7);
    overflow: hidden;
}

.educator_hidden_bio:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 90%
    );
    width: 100%;
    height: 4rem;
}

.educator_bio {
    P {
        overflow: 0;
    }
}

.read_more_button {
    display: flex;
    align-items: center;
    border: none;
    background-color: var(--white);
    color: var(--link-color);
    margin-block-start: var(--margin);
    padding: 0;
    cursor: pointer;

    .read_more_icon {
        fill: var(--link-color);
    }
}
