.buttonContainer {
    display: flex;
    align-items: center;
    height: 2.5em;
    margin-block-end: 0.5rem;
    margin-block-start: 0.5rem;
}

.leftArrow {
    margin-right: 0.25rem;
}

.breadcrumbsBackButton {
    &:hover {
        color: var(--link-color);
        fill: var(--link-color);
        cursor: pointer;
    }
}
