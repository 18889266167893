.postEditor {
    display: flex;
    flex-direction: column;
    height: 100%;

    .textEditor {
        flex-grow: 1;
        min-height: 10rem;
    }
}
