.tabs {
    margin-block-end: 3.875em;
    div {
        position: absolute;
        left: 0;
        width: 100vw;
        max-width: 100%;
        background: var(--bg-3);
        padding: 0 var(--padding);
        box-sizing: border-box;
        overflow-x: auto;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        margin: 0 auto;
        max-width: 1200px;
    }
    li {
        display: inline-flex;
        &:hover {
            border-bottom: 3px solid color-mix(in srgb, white, var(--brand-color) 20%);
        }
    }
    li.activeTab {
        border-bottom: 3px solid var(--brand-color);
        font-weight: var(--font-weight-bold);
    }
    a {
        padding-block: var(--padding);
        padding-inline: var(--padding);
        color: var(--text);
        text-wrap: nowrap;
    }
}
