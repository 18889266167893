.container {
    display: grid;
    grid-template-columns: var(--font-size) 1fr;
    gap: var(--gap-list);
    font-size: var(--font-size-smaller);
    margin-block: var(--margin);
    vertical-align: middle;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.icon {
    color: var(--text-muted);
    width: var(--font-size);
    height: var(--font-size);
}
