.card {
    padding: var(--padding);
    border: 1px solid var(--border);
    border-radius: var(--rounded-corners-standard);
    box-shadow: var(--card-box-shadow);
    padding-bottom: 0;
}

.postHeader {
    display: flex;
    gap: var(--padding-small);

    .avatar {
        aspect-ratio: 1;
        height: 36px;
        border-radius: 100%;
        overflow: hidden;
    }
}
.name {
    display: flex;
    flex-direction: column;
    gap: var(--padding-tiny);
}
