.aboutUsSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
        'details locations'
        'carousel_collection carousel_collection';
    gap: var(--section-gap-big);
    margin-block-start: var(--margin-bigger);

    a {
        cursor: pointer;
    }
}

.detailsSection {
    grid-area: details;
}

.locationsSection {
    grid-area: locations;
}

.carouselSection {
    grid-area: carousel_collection;
}

@media only screen and (max-width: 650px) {
    .aboutUsSection {
        grid-template-columns: 1fr;
        grid-template-areas:
            'details'
            'locations'
            'carousel_collection';
        gap: 0;
        margin: 0 var(--margin);
    }
}

@media only screen and (min-width: 651px) and (max-width: 950px) {
    .aboutUsSection {
        grid-template-columns: 1fr;
        grid-template-areas:
            'details'
            'locations'
            'carousel_collection';
        gap: 0;
        margin: 0 var(--margin);
    }
}
