.dateTimeLayout {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    margin-block-start: var(--margin);
    padding-block-start: var(--padding-smaller);
    border-block-start: 1px solid var(--border);

    &:first-of-type {
        border-block-start: none;
    }

    .calendarIcon {
        margin-inline-end: var(--margin);
    }

    .dateInformation {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: auto;
        gap: var(--gap);
        > * {
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &[data-has-passed] {
        color: var(--text-muted);
    }
}
