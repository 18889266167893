.container {
    max-width: 600px;
    margin: 0 auto;
}

.loadMoreButtonWrapper {
    display: flex;
    justify-content: center;
    margin-block-start: var(--margin-large);
    margin-block-end: var(--margin-large);
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-block-end: 1em;
}

.emptyPlaceholder {
    text-align: center;
}

.reviewsList {
    list-style: none;
    margin: 0;
    padding: 0;
}
