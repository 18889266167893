.postComposer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .errorContainer {
        display: flex;
        justify-content: center;

        .errorAlert {
            margin-block-end: unset;
        }
    }
}
