.spinnerOut {
    width: 3rem;
    margin: 5rem auto;
    display: block;
}

.spinnerIn {
    display: block;
    stroke: var(--brand-color);
}
