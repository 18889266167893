.badge {
    --badgeSurface: var(--warning-bg);
    --onBadgeSurface: var(--warning-border);

    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: var(--rounded-corners-pill);
    text-align: center;
    font-weight: var(--font-weight-bold);
    gap: var(--padding-tiny);
    user-select: none;
    font-size: var(--badgeFontSize);
    background-color: var(--warning-bg);
    color: var(--warning-border);
    text-transform: capitalize;
    outline: 1px solid var(--warning-border);
    outline-offset: -1px;

    .icon {
        svg {
            fill: var(--onBadgeSurface);
            width: 1em;
            height: 1em;
        }
    }

    &.presetBadge {
        --badgeFontSize: var(--font-size-extra-small);

        padding-inline-start: var(--padding-small);
        padding-inline-end: var(--padding);
        padding-block: var(--padding-tiny);
    }

    &.presetLabel {
        --badgeFontSize: var(--font-size-tiny);

        padding-inline: var(--padding);
        padding-block: var(--padding-tiny);
    }
}
