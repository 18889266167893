.day {
    display: block;
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    background: var(--bg-2);
    border-radius: var(--border-radius);
    margin: 0.2rem;

    &[data-selected='true'] {
        background: var(--cademy-blue);
        color: white;
    }

    &[data-disabled='false']:hover {
        cursor: pointer;
        filter: brightness(0.95);
    }

    &[data-disabled='true'] {
        background: none;
        color: var(--text-muted);
        cursor: default;
    }
}
