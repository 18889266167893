.AttachmentListItem {
    padding: var(--padding-small);
    font-size: var(--font-size-extra-small);
    border-radius: var(--rounded-corners-pronounced);
    height: 3rem;
    border: 1px solid var(--border);

    &Container {
        display: grid;
        grid-template-columns: 1fr min-content;
        align-items: center;
    }

    &Content {
        display: grid;
        grid-template-columns: min-content 7rem;
        align-items: center;
        gap: var(--gap-list-small);
    }

    &Preview {
        position: relative;
        display: inline-flex;
        height: 2.5rem;
        width: 2.5rem;
    }

    &Info {
        display: grid;
        grid-template-rows: min-content min-content;
        gap: var(--gap-list-smaller);
    }

    &FileName {
        margin: 0;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &MimeType {
        margin: 0;
        color: var(--text-muted);
    }

    &Button {
        display: flex;
        align-items: flex-start;
        margin-left: var(--margin);
        border: none;
        padding: 0;
        fill: var(--red);
        background-color: transparent;

        &:hover {
            fill: var(--red-hover);
            border: none;
        }

        &:focus {
            fill: var(--red);
            border: none;
            outline: none;
        }
    }
}

.AttachmentListItemProgress {
    width: 100%;
    color: var(--brand-color);
    margin-block: var(--margin-small);
}

.Spinner {
    height: 1.5rem;
    width: 1.5rem;
}

.AttachmentListItemMedia {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--border-radius);
}
