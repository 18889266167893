.primary_location_section {
    display: flex;
    flex-direction: column;
    gap: var(--gap-list-small);

    address {
        font-style: normal;
    }
}

.location_breakdown {
    display: flex;
    flex-direction: column;
    gap: var(--gap-list-smaller);
}

.directions_icon {
    margin-inline-start: 4px;
    fill: var(--link-color);
}
