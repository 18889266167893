.container {
    footer {
        max-height: 80px;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
    }
}

.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--margin-giant) 0;
    gap: var(--padding);

    h3,
    p {
        margin: 0;
    }
}

.emptyStateIcon {
    padding: 1.5rem;
    background: var(--border);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: var(--text);
    }
}
