.dateIcon {
    display: flex;
    justify-content: stretch;
    align-items: center;
    flex-direction: column;

    height: 2.5em;
    aspect-ratio: 1;
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow:
        0 0 1px rgb(67 90 111 / 30%),
        0 2px 4px -2px rgb(67 90 111 / 47%);

    span:first-of-type {
        width: 100%;
        color: #fff;
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
        background-color: var(--red);
        font-size: var(--font-size-tiny);
        font-weight: 900;
        padding-block: 2px;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
    }

    span:last-of-type {
        font-weight: var(--font-weight-bolder);
        display: flex;
        align-items: center;
        height: 100%;
    }
}
