.awaitingApprovalCard {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .cardTitle {
        margin-block-end: 0;
    }

    .actionButtonRow {
        display: flex;
        gap: 1rem;
    }
}
