.container {
    display: flex;
    flex-direction: column;
    gap: var(--padding);
    padding: var(--padding);
    border: 1px solid var(--border);
    border-radius: var(--rounded-corners-standard);
    box-shadow: var(--card-box-shadow);
}

.contents {
    display: flex;
    flex-direction: column;
    gap: var(--padding-small);
}
