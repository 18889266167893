.detailsCard {
    container-type: inline-size;
}

.dateTimeContainer {
    .dateTimeLayout {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        margin-block-start: 0.5em;
        padding-block-start: 0.5em;
        border-block-start: 1px solid var(--border);

        &:first-of-type {
            border-block-start: none;
        }

        .calendarIcon {
            margin-inline-end: 0.5em;
        }

        .dateInformation {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            overflow: auto;
            > * {
                max-width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &[data-has-passed] {
            color: var(--text-muted);
        }
    }
}

.addressContainer {
    display: grid;
    grid-template-columns: 1fr;
    margin-block-start: 1em;
    padding-block-start: 1em;
    border-top: 1px solid var(--border);
    gap: var(--gap-list-small);

    .addressInformation {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        h2 {
            margin: 0;

            &:last-of-type {
                margin-block: 0.5em;
            }
        }

        .directionButtons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 0.2em;
            width: 100%;

            span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                line-height: 30px;
                border-radius: 5px;
                color: var(--blue200);
                background-color: var(--blue50);
                cursor: pointer;
                padding: 0.1em;

                a {
                    display: flex;

                    svg {
                        height: 20px;
                        width: 20px;

                        path {
                            fill: var(--blue);
                        }
                    }
                }
            }
        }
    }

    .map {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.joinInstructionsContainer {
    display: grid;
    grid-template-columns: 1fr;
    margin-block-start: 1em;
    padding-block-start: 1em;
    border-top: 1px solid var(--border);
    gap: var(--gap-list-smaller);
    .joinInstructionsButton {
        width: min-content;
        margin-top: var(--margin);
    }
}

@container (min-width: 570px) {
    .addressContainer {
        grid-template-columns: 2fr 3fr;
        gap: 0;
    }
}
