.searchSection {
    margin-block-start: var(--padding);
    display: flex;
    gap: var(--padding-small);

    .searchInputContainer {
        position: relative;
        input {
            border-radius: var(--rounded-corners-pill);
            border: 1px solid var(--border);
            padding-inline-start: calc((var(--font-size) * 2) + var(--padding-small));
            padding-inline-end: var(--padding);
            height: 2.25rem;
        }
        > svg {
            position: absolute;
            align-self: center;
            top: 0.75em;
            left: 0.85em;
            width: 1em;
            height: 1em;
        }
    }
}
