.openingHoursSection {
    margin-block: 2rem;
}

.openingHoursItem {
    display: grid;
    grid-template-columns: 25px 100px 1fr;
    align-items: baseline;
    margin-block-end: var(--margin);
    flex-wrap: nowrap;
}

.openingHoursIcon {
    width: var(--font-size);
    height: var(--font-size);
    margin-inline-end: var(--margin);
    fill: var(--icon-fill-light);
    transform: translateY(2px);
}

.openingHoursDay {
    font-weight: var(--font-weight-bolder);
    width: 100px;
    margin-inline-end: var(--margin);
}

.openingHoursHours {
    flex-wrap: nowrap;
}
