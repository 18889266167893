.skeleton {
    margin-block-start: var(--padding-smaller);
}

.trigger {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.8em;
    gap: var(--padding-tiny);
}

.trigger:hover {
    text-decoration: underline;
}
