.container {
    ol {
        margin: 0;
        padding-left: 0;
        list-style: none;
    }

    li {
        display: inline;
        font-weight: var(--font-weight-bolder);
        &[aria-current='step'] {
            color: var(--link-color);
            & ~ li {
                color: var(--text-muted);
            }
        }
        &[aria-current='page'] {
            color: var(--text-muted);
            a {
                color: var(--text-muted);
            }
        }
        & + li::before {
            display: inline-block;
            margin: var(--margin-small);
            color: var(--link-color);
            content: '\203A';
        }
    }
}
