.confirmationStepHeader {
    display: flex;
    align-items: center;
    gap: var(--gap-list-small);
    margin-block-start: 1em;
    margin-block-end: 1em;
}

.heading {
    margin: 0;
}

.voucherReference {
    color: var(--text-muted);
    font-size: var(--font-size-small);
    margin-block: var(--margin-big);
    display: block;
}

.confirmedIcon {
    height: 1.75em;
    width: 1.75em;
    fill: var(--green);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.buttons {
    display: flex;
    gap: var(--gap-list);
    align-items: center;
    flex-wrap: wrap;

    button {
        cursor: pointer;
    }
}

@media screen and (max-width: 767px) {
    .confirmationStepHeader {
        flex-direction: column;
        justify-content: center;
    }

    .confirmedIcon {
        height: 3.5em;
        width: 3.5em;
        margin-block: var(--margin);
    }
}
