.courseGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--padding);
    margin: 0;
    padding: 0;
    margin-block: var(--padding);
    list-style: none;
}

@media (max-width: 529px) {
    .courseGrid {
        margin: var(--margin-big) auto;
    }
}

@media (min-width: 530px) {
    .courseGrid {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 768px) {
    .courseGrid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (min-width: 1100px) {
    .courseGrid {
        grid-template-columns: repeat(4, minmax(250px, 1fr));
        gap: 20px;
    }
}
