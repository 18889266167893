.card {
    background: var(--white);
    padding: var(--padding);
    border-radius: var(--rounded-corners-standard);
    min-width: 20rem;
    text-align: center;
    height: fit-content;
}

.author_img {
    border-radius: 100%;
    margin-block-start: -50px;
    border: 2px solid var(--cademy-brand-color);
}

.stars {
    margin-block-start: 1em;
    color: var(--cademy-brand-color);
}

.author {
    font-weight: 700;
}
