.NavTabs {
    display: flex;
    flex-direction: column;
}

.Container {
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        li {
            display: inline-flex;
            &:hover {
                border-bottom: 3px solid color-mix(in srgb, white, var(--brand-color) 20%);
            }
            &[data-active] {
                border-bottom: 3px solid var(--brand-color);
                font-weight: var(--font-weight-bold);
            }
            a {
                padding-block: var(--padding);
                padding-inline: var(--padding);
                color: var(--text);
                text-wrap: nowrap;
            }
        }
    }
}
