.key_details_section {
    ul {
        display: flex;
        flex-direction: column;
        gap: var(--gap-list);
    }
}

.trust_score {
    color: var(--icon-high-rating);
    font-weight: var(--font-weight-bold);
}

.key_details_item {
    display: flex;
    align-items: center;
}

.key_details_primary_icon,
.key_details_secondary_icon {
    height: var(--font-size);
    width: var(--font-size);
    margin-inline-end: var(--margin);
}

.key_details_secondary_icon {
    fill: var(--icon-fill-light);
}

.key_details_primary_icon {
    fill: var(--icon-high-rating);
}
