.pill_list {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    gap: var(--gap-list-small);
    flex-wrap: wrap;
    padding: 0;

    &_item,
    &_item_link {
        font-size: var(--font-size-smaller);
        padding: var(--padding-smaller);
        border: 1px var(--border-muted) solid;
        background-color: var(--item-bg-light);
        border-radius: var(--rounded-corners-pill);
    }
    &_item_link {
        cursor: pointer;

        a {
            color: var(--text);
            white-space: nowrap;
        }

        &:hover {
            color: var(--link-color);
            background-color: var(--item-bg-light);

            a {
                color: var(--link-color);
            }
        }
    }
}
