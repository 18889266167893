.MediaAttachmentListItem {
    position: relative;
    display: inline-flex;
    border-radius: var(--rounded-corners-pronounced);
    height: auto;
    aspect-ratio: 1 / 1;
    border: 1px solid var(--border);

    &Button {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        z-index: 1004;
        padding: 0;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        fill: var(--red);
        border: 1px solid var(--border);
        opacity: 0.75;

        &:hover {
            fill: var(--red-hover);
            opacity: 1;
        }

        &:focus {
            fill: var(--red);
            outline: none;
            opacity: 1;
        }
    }

    &Preview {
        border-radius: var(--border-radius);
        height: 100%;
        width: 100%;
    }
}

.errorAlert {
    height: 100%;
    width: 100%;
}

.trigger {
    cursor: pointer;
    position: absolute;
    bottom: 0.5em;
    left: 0;
    z-index: 1004;
    height: 2rem;
    width: 2.5rem;
    margin-left: var(--margin);
    font-size: var(--font-size-extra-small);
    opacity: 0.75;

    &:hover {
        opacity: 1;
    }
}
