.heading {
    margin: 0;
}

.applicationReference {
    color: var(--text-muted);
    font-size: var(--font-size-small);
    margin-block-start: var(--margin-big);
    display: block;
}
