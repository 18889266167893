.modal {
    width: 100%;
    max-width: 700px;
    max-height: 90vh;
    animation: modalEnter 0.2s ease-out;
    will-change: transform, opacity;

    .content {
        display: flex;
        flex-direction: column;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: var(--padding);
        padding-block-end: 0;

        .title {
            font-weight: bold;
            font-size: 1.15rem;
        }

        .close {
            border-radius: 50%;
            border: none;
            outline: none;
        }

        .close svg {
            opacity: 0.8;
        }

        .close:hover {
            background-color: var(--bg-grey);
        }
    }
}

.postComposer {
    padding: var(--padding);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: var(--gap-list-smaller);
        margin-block-start: var(--padding);
    }

    .controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .adminOptions {
            display: flex;
            align-items: center;
            gap: var(--padding-small);
            margin-inline-end: var(--padding);

            label {
                display: flex;
                align-items: center;
                gap: var(--padding-small);
                font-size: var(--font-size);
                color: var(--text);
                cursor: pointer;

                input[type='checkbox'] {
                    width: 1.1em;
                    height: 1.1em;
                    margin: 0;
                    cursor: pointer;
                    accent-color: var(--brand-color);
                }
            }

            div {
                display: flex;
                align-items: center;
                color: var(--text-muted);
                cursor: help;

                &:hover {
                    color: var(--text);
                }

                svg {
                    width: 14px;
                    height: 14px;
                    fill: currentColor;
                }
            }
        }

        .postButton {
            display: inline-flex;
            align-items: center;
            gap: var(--padding-small);

            .postButtonIcon {
                width: var(--font-size);
                height: var(--font-size);
                fill: currentColor;
                flex-shrink: 0;
                margin-inline-end: var(--padding-small);
            }
        }

        .updatePostButton {
            margin-block-start: var(--margin);
        }
    }

    @media screen and (max-width: 767px) {
        .footer {
            flex-direction: column;
            gap: var(--padding);
        }

        .controls {
            flex-direction: column;
            align-items: stretch;
            gap: var(--padding-small);

            .adminOptions {
                margin-inline-end: 0;
                justify-content: space-between;
            }

            .postButton {
                justify-content: center;
            }
        }
    }
}

@keyframes modalEnter {
    from {
        opacity: 0;
        transform: scale(0.98) translateY(5px);
    }
    to {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@media screen and (max-width: 767px) {
    .modal {
        height: 100dvh;
    }
}
