.modal {
    width: 100%;
    max-width: 700px;

    .content {
        display: flex;
        flex-direction: column;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-block-end: 1px solid var(--border);
        padding: var(--padding-tiny);

        .title {
            font-weight: bold;
        }

        .close {
            border-radius: 50%;
            border: none;
            outline: none;
        }

        .close svg {
            opacity: 0.8;
        }

        .close:hover {
            background-color: var(--bg-grey);
        }
    }
}

.picturePrompt {
    padding: var(--padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

@media screen and (max-width: 767px) {
    .modal {
        max-width: 90%;
    }
}
