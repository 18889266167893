.actionBar {
    display: flex;
    justify-content: space-between;
    font-size: 0px;

    a {
        padding: 0 1rem;
    }

    .leftArrowIcon {
        fill: var(--grey);
    }

    .commentIcon {
        width: 16px;
        height: 16px;
        fill: var(--grey);
    }
}

@media screen and (min-width: 550px) {
    .actionBar {
        font-size: var(--font-size);
    }
    .leftArrowIcon {
        margin-inline-end: 0.5em;
    }
    .commentIcon {
        margin-inline-end: 0.5em;
    }
}
