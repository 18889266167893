.highlight {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: var(--gap-list-small);
    font-size: var(--font-size-smaller);
    font-weight: var(--font-weight-bolder);

    .icon {
        background-color: var(--bg-3);
        height: 2.5rem;
        width: 2.5rem;
        border-radius: var(--border-radius);
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 1rem;
            height: 1rem;
            color: var(--brand-color);
            fill: var(--brand-color);
        }
    }
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
    margin-block-end: 0;
}
