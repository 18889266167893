.event {
    font-size: var(--font-size-smaller);
    time {
        font-weight: var(--font-weight-bold);
        display: block;
    }
    background: var(--cademy-blue);
    color: var(--white);
    border-radius: var(--border-radius);
    margin-inline: var(--margin-small);
    margin-block: var(--margin);
    padding: var(--padding-small);
}
