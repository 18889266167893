.option {
    cursor: pointer;
    input {
        height: var(--font-size-h2);
        width: var(--font-size-h2);
        margin: 0;
        margin-inline-end: var(--margin);
        accent-color: var(--brand-color);

        &:disabled {
            cursor: not-allowed;
        }
    }

    .name {
        font-weight: var(--font-weight-bolder);
    }

    margin-block-end: var(--margin);
    padding: var(--padding-small) !important;

    .headerBar {
        display: grid;
        width: 100%;
        grid-template-columns: max-content 1fr max-content;
        align-items: center;
        font-size: var(--font-size);
    }

    .description {
        color: var(--text-muted);
        font-size: var(--font-size-smaller);
        margin: 0;
    }

    .saleDate {
        color: var(--orange);
        font-size: var(--font-size-smaller);
        font-weight: var(--font-weight-bolder);
        margin-block-start: 1rem;
        margin-block-end: 0;
    }
}

.selected {
    background: var(--brand-hover-color);
    outline: 2px solid var(--brand-color);
}
