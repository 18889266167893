.pollOption {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid var(--border);
    border-radius: var(--border-radius);

    input {
        width: 100%;
        padding: var(--padding);
        border-radius: var(--border-radius);
        border: unset;
        font-size: var(--font-size);
    }

    button {
        position: absolute;
        right: var(--padding-small);
        top: 50%;
        transform: translateY(-50%);
        padding: var(--padding-tiny);
        border: unset;
        background: var(--white);
        cursor: pointer;

        &:disabled {
            display: none;
        }

        svg {
            path {
                fill: var(--red);
            }
        }
    }
}

.duplicate {
    input {
        border: 1px solid var(--red);
    }
}
