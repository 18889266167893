.author {
    display: flex;
    gap: var(--padding-tiny);
    align-items: center;
    flex-wrap: wrap;
}

.authorName {
    margin-inline-end: var(--padding-tiny);
}

.postedOn {
    font-size: var(--font-size-extra-small);
    color: var(--text-muted);
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: var(--padding-tiny);
}

@media screen and (max-width: 767px) {
    .author {
        display: grid;
        grid-template-columns: auto auto 1fr;
        grid-template-rows: auto auto;
        gap: var(--padding-tiny);
        align-items: center;
    }

    .authorName {
        grid-column: 1;
        grid-row: 1;
        margin-inline-end: var(--padding-tiny);
    }

    .adminBadge {
        grid-column: 2;
        grid-row: 1;
        margin-inline-start: 0;
    }

    .postedOn {
        grid-column: 1 / -1;
        grid-row: 2;
        font-size: var(--font-size-extra-small);
    }
}
