.optionHeading {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-bolder);
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--margin);
    align-items: center;
}

.optionsContainer {
    > [role='radio'] {
        padding: var(--padding);
        border: 1px solid var(--border);
        border-block-end: none;
        &:first-of-type {
            border-start-start-radius: var(--rounded-corners-standard);
            border-start-end-radius: var(--rounded-corners-standard);
        }
        &:last-of-type {
            border-end-start-radius: var(--rounded-corners-standard);
            border-end-end-radius: var(--rounded-corners-standard);
            border-block-end: 1px solid var(--border);
        }

        .radio {
            height: 1em;
            width: 1em;
            border: 1px solid var(--icon-fill-light);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
        }
        &:hover {
            .radio {
                background: var(--bg-2);
            }
        }
        &[aria-checked='true'] {
            .radio,
            .radio:hover {
                background: var(--blue);
            }
            .radioChecked {
                width: 0.475em;
                height: 0.475em;
                background: var(--white);
                border-radius: 50%;
            }
        }
        &[aria-checked='false'] {
            cursor: pointer;
        }
    }
}

.selectedOptionContainer {
    margin-block-start: var(--margin-big);
    padding-block-start: var(--padding);
    border-block-start: 1px solid var(--border);
}
