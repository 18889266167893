.sectionWrapper {
    position: relative;
}

.tabsWrapper {
    display: flex;
    overflow-x: scroll;
    max-width: 90vw;

    &::-webkit-scrollbar {
        display: none;
    }

    &::before,
    &::after {
        inline-size: 15vw;
        block-size: 150px;
        content: '';
        position: absolute;
        background-image: url(https://assets.cademy.io/images/pages/shared/FeaturesCarousel/tab-nav-fade.png);
        background-size: contain;
        z-index: 1;
        pointer-events: none;
    }

    &::after {
        right: 20px;
        transform: rotate(180deg);
    }
}

.tabs_container {
    transition: transform 0.5s ease-in-out;
}

.tabs {
    position: relative;
    display: flex;
    justify-content: center;
    & > :first-child {
        margin-inline-start: 12vw;
    }
    & > :last-child {
        margin-inline-end: 35vw;
    }
}

.tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    padding: 0;
    border: unset;
    color: var(--dark-blue);
    gap: 15px;
    white-space: nowrap;
    font-family:
        'SF UI Text',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Helvetica,
        Arial,
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol';
    font-weight: 500;
    font-size: var(--font-size);
    transition: all ease-in-out 0.2s;
    padding-block-start: 1rem;

    .label {
        width: 115px;
        margin-inline: -10px;
        text-wrap: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }

    .icon_container {
        block-size: 55px;
        inline-size: 55px;
        border: 10px;
        background-color: var(--bg-2);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 0.2s;
        border-radius: 12px;
        clip-path: path(
            'M55 27.5C55 21.3548 54.7339 16.3754 54.3904 12.5079C53.8158 6.03697 48.963 1.18424 42.4921 0.609579C38.6246 0.266118 33.6452 0 27.5 0C21.3548 0 16.3754 0.266118 12.5079 0.609579C6.03697 1.18424 1.18424 6.03697 0.609579 12.5079C0.266118 16.3754 0 21.3548 0 27.5C0 33.8762 0.286505 38.9973 0.648635 42.9236C1.22382 49.1598 5.82715 53.8374 12.0652 54.3924C15.9146 54.7349 20.9952 55 27.5 55C34.0048 55 39.0854 54.7349 42.9348 54.3924C49.1729 53.8374 53.7762 49.1598 54.3514 42.9236C54.7135 38.9973 55 33.8762 55 27.5Z'
        );

        svg {
            flex: 0 0 1.25rem;
            fill: var(--cademy-brand-color);
        }
    }

    &:hover {
        cursor: pointer;
        transform: translateY(-3px);
    }
}

.selected_tab {
    color: var(--cademy-brand-color);
    transition: all ease-in-out 0.2s;

    .label {
        margin-inline: 0px;
        font-weight: 700;
    }

    .icon_container {
        transform: scale(1.25);
        background-color: var(--cademy-brand-color);

        svg {
            fill: var(--on-cademy-brand-color);
        }
    }
}

.scroll_hints {
    display: none;
}

.anim_section {
    display: flex;
    gap: 4%;
    align-items: center;
    justify-content: flex-end;
    min-height: 600px;
}

.anim_section > div:first-of-type {
    flex: 1;
}

.anim_builder_container {
    max-height: 600px;
}

.anim_embeds_container {
    max-height: 600px;
}

.anim_crm_container {
    max-width: 500px;
    max-height: 600px;
    margin: 0 auto;
}

@media screen and (max-width: 1000px) {
    .scroll_hints {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-block-end: 2rem;
        color: var(--text-muted);

        span:first-child {
            animation: arrowAnimation 1s infinite;
        }

        span:last-child {
            animation: arrowAnimation 1s infinite;
        }
    }
}

@media (max-width: 725px) {
    .anim_section {
        flex-direction: column;
        gap: 50px;
        margin: 0;
        justify-content: center;
        padding-block-end: 100px;
    }

    .anim_builder_container {
        margin-left: 0;
        width: 90vw;
        margin-block-start: var(--margin-giant);
        position: relative;
        left: unset;
        top: unset;
    }

    .anim_embeds_container {
        margin-inline-start: 0;
        margin-block-end: -50px;
        width: 90vw;
        position: relative;
        left: unset;
        top: unset;
    }

    .anim_crm_container {
        margin-inline-start: 0;
        margin-block-end: -50px;
        width: 90vw;
        position: relative;
        left: unset;
        top: unset;
    }
}

@media (max-width: 725px) {
    .tabs {
        & > :first-child {
            margin-inline-start: 2vw;
        }
        & > :last-child {
            margin-inline-end: 20vw;
        }
    }
}

@keyframes arrowAnimation {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
}
