.cards_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: stretch;
    gap: 2rem;
    margin-block: var(--margin-bigger);
}

.price_card {
    background-color: var(--white);
    border: 1px solid var(--border);
    border-radius: var(--rounded-corners-pronounced);
    padding: 2rem;
    height: 88%;

    .card_heading {
        color: var(--cademy-brand-color);
        margin: 0;
        text-transform: uppercase;
    }

    .price_container {
        h2 {
            font-weight: 600;

            span {
                color: var(--text-muted);
                font-size: var(--font-size-extra-small);
                font-weight: 500;
            }
        }
    }

    .fancy_points_container {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .cta_container {
        margin-block: 1rem;
    }
}

.fancy_point {
    display: flex;
    height: fit-content;
    align-items: center;
    gap: 0.5rem;

    .icon_container {
        width: 0.8rem;
        height: 0.8rem;
        margin-block-end: 4px;
        flex: 0 0 0.8rem;
    }

    p {
        margin: 0;
    }
}

.billing_buttons {
    display: flex;
    justify-content: center;
    z-index: 1;
    position: relative;

    .billing_btn {
        padding-block: 0.6rem;
        padding-inline: 1.2rem;
        border: 1px solid var(--border);
        font-weight: 500;
        background-color: var(--bg-1);
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
            background-color: var(--blue100);
        }

        .saveBadge {
            background-color: var(--blue25);
            padding-inline: 8px;
            padding-block: 4px;
            border-radius: var(--rounded-corners-pill);
            margin-inline-start: 5px;
            text-transform: uppercase;
            font-size: 9pt;
            color: var(--black);
        }
    }

    :first-child {
        border-radius: var(--rounded-corners-pill) 0 0 var(--rounded-corners-pill);
    }

    :last-child {
        border-radius: 0 var(--rounded-corners-pill) var(--rounded-corners-pill) 0;
        padding-inline-end: 0.8rem;
    }

    .selected {
        background: var(--cademy-brand-color);
        color: var(--on-cademy-brand-color);
    }
}

.togglesContainer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-block: 2rem;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        gap: 1rem;
    }
}

.tooltip_text {
    text-decoration: underline dotted;
    display: inline;
    cursor: help;
}

.enterpriseBanner {
    background-color: var(--cademy-brand-color);
    border-radius: var(--rounded-corners-pronounced);
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: var(--gap-list);
    color: var(--on-cademy-brand-color);

    .ctaContainer {
        h3 {
            margin-block-start: 0;
        }

        a {
            width: 90%;
        }
    }

    .enterpriseFeaturesList {
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap-list);
        svg {
            fill: var(--on-cademy-brand-color);
        }
    }
}

@media only screen and (max-width: 1000px) {
    .cards_container {
        gap: 5px;
    }
}

@media only screen and (max-width: 800px) {
    .cards_container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .price_card {
        height: unset;
    }

    .cta_container {
        flex-wrap: wrap;
        justify-content: center;
    }

    .enterpriseBanner {
        grid-template-columns: 1fr;
        .ctaContainer {
            a {
                width: 100%;
            }
        }
    }
}
