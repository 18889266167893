.container {
    padding: var(--padding);
    border: 1px solid var(--border);
    border-radius: var(--rounded-corners-standard);
    box-shadow: var(--card-box-shadow);
}

.content {
    display: flex;
    align-items: center;
    gap: var(--margin);
}

.avatarWrapper {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
}

.inputPlaceholder {
    flex-grow: 1;
    padding: var(--padding-small) 16px;
    background: var(--bg-2);
    border-radius: var(--rounded-corners-pill);
    color: var(--text-muted);
    cursor: pointer;
    box-shadow: inset 0 0 0 transparent;

    &:hover {
        background-color: var(--bg-3);
        box-shadow: inset 0 0px 3px rgba(0, 0, 0, 0.15);
    }
}
