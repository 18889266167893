.weekdayHeading {
    font-weight: bold;
    text-align: center;
    padding: var(--padding);
    border-block-end: 1px solid var(--border);

    &[data-is-first='true'] {
        border-inline-start: 1px solid var(--border);
    }

    &[data-is-last='true'] {
        border-inline-end: 1px solid var(--border);
    }
}
