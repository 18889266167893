.container {
    display: flex;
    flex-direction: row;
    gap: var(--padding-small);
}

.avatar {
    aspect-ratio: 1;
    height: 36px;
    border-radius: 100%;
    overflow: hidden;
}

.name {
    display: flex;
    flex-direction: column;

    .nameAndBadges {
        display: flex;
        flex-direction: row;
        gap: var(--padding-small);
        align-items: center;

        strong {
            font-size: var(--font-size);
            color: var(--text);
        }
    }

    .dateRow {
        display: flex;
        align-items: center;
        gap: 0.1em;
        color: var(--text-muted);
    }
}
