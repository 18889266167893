.progress {
    position: absolute;
    width: 85%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progressCircle {
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
}

.progressCircleBack {
    fill: none;
    stroke: #d2d2d2;
    stroke-width: 5px;
}

.progressCircleProg {
    fill: none;
    stroke: var(--brand-color);
    stroke-width: 5px;
    stroke-dasharray: 0 999;
    stroke-dashoffset: 0px;
    transition: stroke-dasharray 0.7s linear 0s;
}

.text {
    position: absolute;
    text-align: center;
    font-size: 100%;
    text-shadow: var(--brand-color) 0px 0px 5px;
}
