.slot {
    display: block;
    --_backgroundColor: var(--cardBackground, white);
    border-radius: var(--border-radius);
    border: 1px solid var(--border);
    background-color: var(--_backgroundColor);
    box-shadow: var(--card-box-shadow);
    text-align: center;
    padding: var(--padding-small);
    cursor: pointer;
    font-size: var(--font-size-smaller);
    margin-block-end: var(--padding);
    margin-inline-end: var(--padding);
    font-variant-numeric: tabular-nums;
}

.slot:hover,
.selected {
    background: var(--brand-color);
    color: var(--on-brand-color);
}
