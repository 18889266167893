.banner {
    display: flex;
    align-items: center;
    gap: var(--gap-list-small);
    padding: var(--padding);
    background-color: var(--bg-grey);
    border: 1px solid var(--border);
    border-radius: var(--border-radius);
    margin-block-start: var(--margin);
    fill: var(--link-color);
    font-weight: var(--font-weight-bolder);
    cursor: pointer;

    &:hover {
        color: var(--blue);
        fill: var(--blue);
        border-color: var(--blue);
    }
}
