.summaryContainer {
    h3 {
        margin-block-start: 0em;
        margin-block-end: 0.5em;
    }

    p {
        margin: 0;
    }

    .description {
        padding: 0.5em 0;

        a {
            overflow-wrap: anywhere;
        }
    }
}
.summaryHighlights {
    display: flex;
    flex-direction: column;
    align-items: left;
    border-bottom: 1px solid var(--border);
    padding: 25px 0;
    font-size: 11pt;
}

.summaryTotal {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    font-weight: 700;
}

.summaryIcon {
    margin-right: 10px;
    font-size: 20px;
    width: 10px;
}
