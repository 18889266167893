.poweredByContainer {
    display: flex;
    justify-content: center;
    margin: var(--margin-big) 0;
}

.poweredByContent {
    display: flex;
    align-items: center;
    gap: var(--gap-list-smaller);

    &Description {
        white-space: nowrap;
        text-decoration: none;
        color: var(--text-muted);
        font-size: var(--font-size-smaller);
        font-weight: var(--font-weight-bolder);
    }

    &Image {
        position: relative;
        width: 6rem;
        height: 3rem;

        img {
            object-fit: contain;
        }
    }
}
