.dropZone {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--light-grey);
    border-radius: var(--border-radius);
    color: var(--white);
    z-index: 1006;
    pointer-events: none;
    gap: var(--gap-list-small);
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-bold);

    > svg {
        fill: var(--white);
    }
}
