.container {
    container-type: inline-size;
}
.calendarEvents {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: max-content;
    grid-auto-rows: minmax(180px, 1fr);
    grid-auto-rows: minmax(calc(100cqw / 7), max-content);
    :nth-child(7n).day {
        border-inline-end: 1px solid var(--border);
    }
    :nth-last-child(-n + 7).day {
        border-block-end: 1px solid var(--border);
    }
    :last-child.day {
        border-inline-end: 1px solid var(--border);
    }
}

.day {
    min-height: 0;
    border-inline-start: 1px solid var(--border);
    border-block-start: 1px solid var(--border);
    &[data-previous-month],
    &[data-passed] {
        .dayLabel {
            color: var(--text-muted);
        }
    }
    .dayLabel {
        display: block;
        text-align: right;
        padding-inline: var(--padding-small);
        padding-block-start: var(--padding-small);
    }
}
