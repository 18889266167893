.skeleton {
    --shine-width: 20px;
    background-color: color-mix(in srgb, white, var(--brand-color) 20%);
    background-image: linear-gradient(90deg, rgba(white, 0), rgba(white, 0.5), rgba(white, 0));
    background-size: var(--shine-width) 100%;
    background-repeat: no-repeat;
    background-position: top calc(var(--shine-width) * -1) top 0;
    animation: loader 1.3s ease infinite;

    @keyframes loader {
        to {
            background-position: right calc(var(--shine-width) * -1) top 0;
        }
    }
}
