.saveButton {
    svg {
        width: var(--font-size-h2);
        stroke: var(--white);
        stroke-width: 1.5;
        fill: transparent;
        stroke: var(--text-muted);
        margin-inline-end: var(--padding-tiny);
    }
}

.saveButton[data-saved='true'] {
    svg {
        fill: var(--red);
        stroke: rgba(0, 0, 0, 0.45);
    }
}
